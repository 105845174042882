<template>
  <div class="approval-detail">
    <!-- 弹窗 -->
		<div class="list-confirm" v-if="ishsow">
			<div class="list-confirm-bk">
				<h5>以下商品暂停售卖或库存不足</h5>
				<div class="unavailable-items-box">
					<div v-for="(item, index) in unavailable_items" :key="index" class="unavailable-items">
						<img :src="item.base_pic" class="list-confirm-img" />
						<div class="content-box">
							<p class="content-list-name">{{ item.item_name }}</p>
							<div class="content-list-number">x{{ item.number }}</div>
						</div>
					</div>
				</div>
				<div class="list-confirm-btn">
					<div class="list-confirm-btnleft" @click.stop="checkBtnOK">知道了</div>
				</div>
			</div>
		</div>
    <div class="purchase-box">
      <!--头像状态-->
      <div class="top-info df fdr alc jc-sb border-bottom-1px">
        <div class="df fdr">
          <img class="avatar" v-if="detail.heagurl" :src="detail.heagurl"
               alt="avatar">
          <img class="avatar" v-else src="../../../assets/ic_head_default@3x.png"
               alt="avatar">
          <div class="df fdc jc-sb">
            <div class="name">{{detail.nickname}}</div>
            <span class="remark">{{detail.group_name}}</span>
          </div>
        </div>
        <div class="status1" v-if="status===1">待我审批</div>
        <div class="status2" v-if="status===2">{{ current_tab_is_2[detail.examine_status] }}</div>
        <div class="status1" v-if="status===3">待审批</div>
        <div class="status2" v-if="status===4">已被批准</div>
        <div class="status2" v-if="status===5">
          {{current_tab_is_2[detail.examine_status]}}
          </div>
      </div>
      <!--采购详情-->
      <div class="bottom-info df fdc jc-sb">
        <div>订单编号：{{detail.pay_order_sn}}</div>
        <div>采购起始时间：{{detail.create_time|formatDateStd}}</div>
        <div v-if="detail.check_time">采购结束时间：{{detail.check_time|formatDateStd}}</div>
        <div>预算类型：{{detail.budget_type_name}}</div>
        <div>金额：<span class="price">￥{{detail.total_amount}}</span></div>
        <div v-if="detail.remark">备注：<span>{{detail.remark}}</span></div>
      </div>
    </div>
    <!--采购商品-->
    <div class="goods-box df fdc jc-sb" @click="toPurchasingList">
      <div class="df fdr alc jc-sb">
        <div class="name">采购商品</div>
        <div class="nav-detail">查看详情</div>
      </div>
      <div class="goods-img">
        <img v-for="(item,index) in detail.base_pic.slice(0,5)" :key="index" :src="item" alt="goods_img">
        <div v-if="detail.base_pic.length>4" class="img_more">...</div>
      </div>
    </div>
    <!--审批流程-->
    <div class="process-box" v-if="process_list.length">
      <div class="process-item df fdc" v-for="(itemAll,indexALl) in detail.process" :key="indexALl">
        <!-- 0未审核 1同意，2拒绝 （审核后有）-->
        <div v-for="(item,index) in itemAll" v-if="itemAll.length===1">
          <div class="df fdr jc-sb">
            <div class="df fdr">
              <!--头像-->
              <div style="position: relative">
                <img v-if="item.heagurl" class="avatar"
                     :src="item.heagurl"
                     alt="avatar">
                <img v-else class="avatar"
                     src="../../../assets/ic_head_default@3x.png"
                     alt="avatar">
                <!-- 1审核中 2同意 3拒绝 4失败-->
                <img v-if="item.status===2" class="status-icon"
                     src="../../../assets/icon_shenpi_wancheng@3x.png"
                     alt="">
                <!--<icon-svg v-else-if="item.status===0" class="bell" icon-class="yi_icon_shenpi_dengdai"/>-->
                <img v-else-if="item.status===1" class="status-icon"
                     src="../../../assets/icon_shenpi_dengdai@3x.png" alt="">
                <!--拒绝-->
                <img v-else-if="item.status===3" class="status-icon"
                     src="../../../assets/icon_shenpi_jujue@3x.png" alt="">
                     <img v-else-if="item.status===4" class="status-icon"
                     src="../../../assets/icon_shenpi_jujue@3x.png" alt="">
              </div>
              <!--角色信息-->
              <!-- 0未审核 1同意，2拒绝 （审核后有）-->
              <div class="df fdc jc-sb" style="position: relative">
                <div class="name">{{item.nickname}}</div>
                <span class="remark" v-if="item.status===1">{{item.nickname}}(审核中)</span>
                <!--                <span class="remark" v-else-if="item.approval_status===2">{{item.remark}}(审批中)</span>-->
                <span class="remark" v-else-if="item.status===2">{{item.nickname}}(通过)</span>
                <span class="remark" v-else-if="item.status===3">{{item.nickname}}(拒绝)</span>
                <span class="remark" v-else-if="item.status===4">{{item.nickname}}(审核失败)</span>
                <span class="remark" v-else>{{item.nickname}}</span>
              </div>
            </div>
            <span class="remark" v-if="item.update_time">{{item.update_time}}</span>
          </div>
          <div class="df fdr alc">
            <div v-if="indexALl+1===detail.process.length"></div>
            <div v-else-if="item.status===2" class="line"></div>
            <div v-else-if="item.status===3" style="height: 0.94rem" class="dash-line"></div>
            <div v-else class="dash-line"></div>
            <div class="refuse-reason df alc" v-if="item.status===3||item.status===4&&item.content" v-html="item.content"></div>
          </div>
        </div>
        <div v-if="itemAll.length>1">
          <div class="df fdr jc-sb">
            <div class="df fdr">
              <!--头像-->
              <div style="position: relative">
                <!--几个审批人-->
                <div class="item-list flex-center">
                  <icon-svg class="bell" icon-class="yi_icon_xiaoxi_whitex"/>
                </div>
              </div>
              <!--角色信息-->
              <!-- 0未审核 1同意，2拒绝 （审核后有）-->
              <div class="df fdc jc-sb" style="position: relative">
                <div class="name">{{itemAll.length}}个审批人</div>
                <div class="location df fdr">
                  <div class="img-list df fdc" v-for="i in itemAll">
                    <img v-if="i.heagurl" :src="i.heagurl" alt="avatar">
                    <img src="../../../assets/ic_head_default@3x.png" alt="avatar">
                    <span class="remark">{{i.nickname}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="df fdr alc">
            <div v-if="indexALl+1===detail.process.length" style="height: 1.22rem"></div>
            <div v-else-if="itemAll.length>1" style="height: 1.22rem" class="dash-line"></div>
            <div v-else class="dash-line"></div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 1.2rem" v-if="status===1"></div>
    <footer class="df fdr alc jc-sb" v-if="status===1">
      <button class="reject-btn" @click.stop="toRefuse">拒绝</button>
      <button class="allow-btn" @click.stop="passExamine">批准</button>
    </footer>
  </div>
</template>

<script>
import {getExamineDetail, passExamine} from "@/services/my";

export default {
  name: "ApprovalDetail",
  data() {
    return {
      status: 1,
      pay_order_id: '',
      detail: {},
      process_list: [],
      current_tab_is_2:{1:'审核中',2:'审核通过',3:'审核拒绝',4:'审核失败'},
      unavailable_items:'',
      ishsow:false
    }
  },
  methods: {
    getParams() {
      this.pay_order_id = this.$route.query.id ? this.$route.query.id : '';
      this.status = this.$route.query.status ? Number.parseInt(this.$route.query.status) : '';
      if (this.pay_order_id)
        this.getExamineDetail(this.pay_order_id);
      else
        this.$router.go(-1);

    },
    toPurchasingList() {
      this.$router.push({
        path: '/purchasing_list',
        query: {
          id: this.pay_order_id
        }
      });
    },
    toRefuse() {
      this.$router.push({
        path: '/refuse_reason',
        query: {
          id: this.pay_order_id
        }
      });
    },
    /**
     * 采购审批详情
     * @param id 订单id
     * @returns {Promise<void>}
     */
    async getExamineDetail(id) {
      try {
        const res = await getExamineDetail(id);
        if (res.code === 0) {
          const data = res.data;
          this.detail = data;
          this.process_list = data.process
        }
      } catch (e) {
      }
    },
    /**
     * 内采商品审核通过
     * @returns {Promise<void>}
     */
    async passExamine() {
      try {
        const res = await passExamine(this.pay_order_id);
        if (res.code === 0) {
          this.$dialog.toast({
            mes: res.msg,
            icon: "success"
          });
          this.$router.go(-1);
        }else if(res.data.length>0){
           this.unavailable_items=res.data;
           this.ishsow=true;

        }else{
          this.$dialog.toast({
            mes: res.msg,
            // icon: "error"
          });
          this.$router.go(-1);
        }
      } catch (e) {

      }
    },
    checkBtnOK(){
      history.go(-1)
    }
  },
  mounted() {
    this.getParams()
  }
}
</script>

<style scoped lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.approval-detail {
  /*头像*/
.img_more {
        .wh(0.7rem, 0.96rem);
        line-height: 0.96rem;
        text-align: center;
        border-radius: 0.04rem;
        .sc(0.24rem, var(--main-color));
        margin-left: 0.2rem;
        background: #F3F2F5;
        position: absolute;
        right: 0.2rem;
      }
  .avatar {
    .wh(0.8rem, 0.8rem);
    border-radius: 50%;
    object-fit: cover;
    margin-right: 0.24rem;
  }

  .status-icon {
    .wh(0.24rem, 0.24rem);
    position: absolute;
    right: 0.24rem;
    bottom: 0;
  }

  .name {
    .sc(0.28rem, @color-dark-grey);
    line-height: 0.4rem;

  }

  .remark {
    .sc(0.24rem, @color-light-grey);
    line-height: 0.34rem;
  }
   	// 不可支付弹框样式
	.list-confirm {
		position: fixed;
		background-color: rgba(0, 0, 0, 0.5);
		top: 0;
		left: 0;
		width: 100%;
    height: 100%;
    z-index: 99;
		.list-confirm-bk {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transform: -webkit-translate(-50%, -50%);
			width: 80%;
			height: 68%;
			background: #fff;
			border-radius: 0.1rem;
			-webkit-border-radius: 0.1rem;
			.list-confirm-btn {
				display: flex;
				height: 0.7rem;
				font-size: 0.3rem;
				color: #fff;
				text-align: center;
				line-height: 0.7rem;
				position: absolute;
				bottom: 0;
				width: 100%;
				.list-confirm-btnleft {
          flex: 1;
          height: 100%;
          margin-top: 1px;
					background: var(--main-color);
					border-radius: 0 0 0 0.1rem;
					-webkit-border-radius: 0 0 0 0.1rem;
				}
				.list-confirm-btnright {
					flex: 1;
					background: var(--main-color);
					border-radius: 0 0 0.1rem 0;
					-webkit-border-radius: 0 0 0.1rem 0;
				}
			}
			h5 {
				height: 0.8rem;
				text-align: center;
				line-height: 0.8rem;
				border-bottom: 1px solid #dddddd;
			}
			.unavailable-items-box {
				height: 80%;
				overflow-y: auto;
				.unavailable-items {
					padding: 0.16rem 0.23rem;
					display: flex;
					.list-confirm-img {
						width: 1.41rem;
						height: 1.41rem;
						flex: 1;
					}
					.content-box {
						padding: 0.23rem 0 0.23rem 0.23rem;
						flex: 3;
						.content-list-name {
							font-size: 0.28rem;
							max-width: 65%;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							text-overflow: ellipsis;
							word-break: break-all;
							word-wrap: break-word;
							float: left;
							line-height: 0.36rem;
						}
						.content-list-number {
							float: right;
							font-size: 0.28rem;
							padding-top: 0.23rem;
							text-align: right;
							color: #b3b3b3;
						}
					}
				}
			}
		}
	}
  /*采购信息*/

  .purchase-box {
    background: @color-white;
    height: auto !important;
    padding: 0 0.3rem;
    margin-bottom: 0.2rem;

    .top-info {
      height: 1.4rem;


      .status1 {
        .sc(0.28rem, var(--main-color));
      }

      .status2 {
        .wh(1.38rem, 0.56rem);
        line-height: 0.56rem;
        text-align: center;
        .sc(0.24rem, @color-light-grey);
        border-radius: 0.28rem;
        border: 0.02rem solid @color-light-grey;
      }
    }

    .bottom-info {
      .sc(0.28rem, @color-light-grey);
      padding: 0.3rem 0;
      height: auto !important;

      div, span {
        line-height: 0.4rem;
      }

      .price {
        color: var(--main-color);
      }
    }

  }

  /*采购商品*/

  .goods-box {
    height: 2.04rem;
    background: @color-white;
    padding: 0.24rem 0 0.22rem 0.3rem;
    margin-bottom: 0.2rem;

    .nav-detail {
      .wh(1.36rem, 0.4rem);
      .sc(0.28rem, @color-light-grey);
      line-height: 0.4rem;
      background: white url(../../../assets/icon_more@3x.png) no-repeat 100% 50%;
      background-size: 0.14rem 0.24rem;
      margin-right: 0.3rem;
    }

    .goods-img {
      img {
        .wh(0.96rem, 0.96rem);
        margin-right: 0.2rem;
       float: left;
      }
    }
  }

  /*审批流程*/

  .process-box {
    background: @color-white;
    padding: 0.3rem;
    margin-bottom: 0.2rem;

    .item-list {
      .wh(0.8rem, 0.8rem);
      border-radius: 50%;
      background: var(--main-color);
      margin-right: 0.24rem;

      .bell {
        width: 0.48rem !important;
        height: 0.48rem !important;
        color: #fff;
      }
    }

    .location {
      position: absolute;
      top: 0.4rem;
    }

    .img-list {
      img {
        .wh(0.64rem, 0.64rem);
        border-radius: 50%;
        margin-top: 0.2rem;
        margin-right: 0.38rem;
      }
    }

    .line {
      .wh(0.04rem, 0.56rem);
      margin: 0.2rem 0.62rem 0.2rem 0.38rem;
      border: 0.02px solid @color-background-light-grey;
    }

    .dash-line {
      .wh(0.04rem, 0.56rem);
      margin: 0.2rem 0.62rem 0.2rem 0.38rem;
      border: 0.02px dashed @color-background-light-grey;
    }

    .refuse-reason {
      background: #F3F2F5;
      .sc(0.24rem, @color-add-red);
      // .wh(5.86rem, 0.8rem);
      padding: 0.15rem;
      line-height: 1.4;
      white-space: pre-wrap
    }

  }

  /*审批按钮*/

  footer {
    position: fixed;
    bottom: 0;
    background: @color-white;
    padding: 0 0.3rem;
    box-shadow: 0 0.04rem 0.16rem 0 rgba(0, 0, 0, 0.1);
    .wh(100%, 1rem);
    .sc(0.28rem, @color-white);

    .reject-btn, .allow-btn {
      .wh(3.3rem, 0.68rem);
      line-height: 0.68rem;
      border-radius: 0.45rem;
      border: 0;
    }

    .reject-btn {
      background: @color-add-red;
    }

    .allow-btn {
      background: var(--main-color);
    }
  }
}
</style>
