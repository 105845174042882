var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "approval-detail" }, [
    _vm.ishsow
      ? _c("div", { staticClass: "list-confirm" }, [
          _c("div", { staticClass: "list-confirm-bk" }, [
            _c("h5", [_vm._v("以下商品暂停售卖或库存不足")]),
            _c(
              "div",
              { staticClass: "unavailable-items-box" },
              _vm._l(_vm.unavailable_items, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "unavailable-items" },
                  [
                    _c("img", {
                      staticClass: "list-confirm-img",
                      attrs: { src: item.base_pic },
                    }),
                    _c("div", { staticClass: "content-box" }, [
                      _c("p", { staticClass: "content-list-name" }, [
                        _vm._v(_vm._s(item.item_name)),
                      ]),
                      _c("div", { staticClass: "content-list-number" }, [
                        _vm._v("x" + _vm._s(item.number)),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
            _c("div", { staticClass: "list-confirm-btn" }, [
              _c(
                "div",
                {
                  staticClass: "list-confirm-btnleft",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.checkBtnOK.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("知道了")]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "purchase-box" }, [
      _c(
        "div",
        { staticClass: "top-info df fdr alc jc-sb border-bottom-1px" },
        [
          _c("div", { staticClass: "df fdr" }, [
            _vm.detail.heagurl
              ? _c("img", {
                  staticClass: "avatar",
                  attrs: { src: _vm.detail.heagurl, alt: "avatar" },
                })
              : _c("img", {
                  staticClass: "avatar",
                  attrs: {
                    src: require("../../../assets/ic_head_default@3x.png"),
                    alt: "avatar",
                  },
                }),
            _c("div", { staticClass: "df fdc jc-sb" }, [
              _c("div", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.detail.nickname)),
              ]),
              _c("span", { staticClass: "remark" }, [
                _vm._v(_vm._s(_vm.detail.group_name)),
              ]),
            ]),
          ]),
          _vm.status === 1
            ? _c("div", { staticClass: "status1" }, [_vm._v("待我审批")])
            : _vm._e(),
          _vm.status === 2
            ? _c("div", { staticClass: "status2" }, [
                _vm._v(_vm._s(_vm.current_tab_is_2[_vm.detail.examine_status])),
              ])
            : _vm._e(),
          _vm.status === 3
            ? _c("div", { staticClass: "status1" }, [_vm._v("待审批")])
            : _vm._e(),
          _vm.status === 4
            ? _c("div", { staticClass: "status2" }, [_vm._v("已被批准")])
            : _vm._e(),
          _vm.status === 5
            ? _c("div", { staticClass: "status2" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.current_tab_is_2[_vm.detail.examine_status]) +
                    "\n          "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "bottom-info df fdc jc-sb" }, [
        _c("div", [_vm._v("订单编号：" + _vm._s(_vm.detail.pay_order_sn))]),
        _c("div", [
          _vm._v(
            "采购起始时间：" +
              _vm._s(_vm._f("formatDateStd")(_vm.detail.create_time))
          ),
        ]),
        _vm.detail.check_time
          ? _c("div", [
              _vm._v(
                "采购结束时间：" +
                  _vm._s(_vm._f("formatDateStd")(_vm.detail.check_time))
              ),
            ])
          : _vm._e(),
        _c("div", [_vm._v("预算类型：" + _vm._s(_vm.detail.budget_type_name))]),
        _c("div", [
          _vm._v("金额："),
          _c("span", { staticClass: "price" }, [
            _vm._v("￥" + _vm._s(_vm.detail.total_amount)),
          ]),
        ]),
        _vm.detail.remark
          ? _c("div", [
              _vm._v("备注："),
              _c("span", [_vm._v(_vm._s(_vm.detail.remark))]),
            ])
          : _vm._e(),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "goods-box df fdc jc-sb",
        on: { click: _vm.toPurchasingList },
      },
      [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "goods-img" },
          [
            _vm._l(_vm.detail.base_pic.slice(0, 5), function (item, index) {
              return _c("img", {
                key: index,
                attrs: { src: item, alt: "goods_img" },
              })
            }),
            _vm.detail.base_pic.length > 4
              ? _c("div", { staticClass: "img_more" }, [_vm._v("...")])
              : _vm._e(),
          ],
          2
        ),
      ]
    ),
    _vm.process_list.length
      ? _c(
          "div",
          { staticClass: "process-box" },
          _vm._l(_vm.detail.process, function (itemAll, indexALl) {
            return _c(
              "div",
              { key: indexALl, staticClass: "process-item df fdc" },
              [
                _vm._l(itemAll, function (item, index) {
                  return itemAll.length === 1
                    ? _c("div", [
                        _c("div", { staticClass: "df fdr jc-sb" }, [
                          _c("div", { staticClass: "df fdr" }, [
                            _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                item.heagurl
                                  ? _c("img", {
                                      staticClass: "avatar",
                                      attrs: {
                                        src: item.heagurl,
                                        alt: "avatar",
                                      },
                                    })
                                  : _c("img", {
                                      staticClass: "avatar",
                                      attrs: {
                                        src: require("../../../assets/ic_head_default@3x.png"),
                                        alt: "avatar",
                                      },
                                    }),
                                item.status === 2
                                  ? _c("img", {
                                      staticClass: "status-icon",
                                      attrs: {
                                        src: require("../../../assets/icon_shenpi_wancheng@3x.png"),
                                        alt: "",
                                      },
                                    })
                                  : item.status === 1
                                  ? _c("img", {
                                      staticClass: "status-icon",
                                      attrs: {
                                        src: require("../../../assets/icon_shenpi_dengdai@3x.png"),
                                        alt: "",
                                      },
                                    })
                                  : item.status === 3
                                  ? _c("img", {
                                      staticClass: "status-icon",
                                      attrs: {
                                        src: require("../../../assets/icon_shenpi_jujue@3x.png"),
                                        alt: "",
                                      },
                                    })
                                  : item.status === 4
                                  ? _c("img", {
                                      staticClass: "status-icon",
                                      attrs: {
                                        src: require("../../../assets/icon_shenpi_jujue@3x.png"),
                                        alt: "",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "df fdc jc-sb",
                                staticStyle: { position: "relative" },
                              },
                              [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(_vm._s(item.nickname)),
                                ]),
                                item.status === 1
                                  ? _c("span", { staticClass: "remark" }, [
                                      _vm._v(
                                        _vm._s(item.nickname) + "(审核中)"
                                      ),
                                    ])
                                  : item.status === 2
                                  ? _c("span", { staticClass: "remark" }, [
                                      _vm._v(_vm._s(item.nickname) + "(通过)"),
                                    ])
                                  : item.status === 3
                                  ? _c("span", { staticClass: "remark" }, [
                                      _vm._v(_vm._s(item.nickname) + "(拒绝)"),
                                    ])
                                  : item.status === 4
                                  ? _c("span", { staticClass: "remark" }, [
                                      _vm._v(
                                        _vm._s(item.nickname) + "(审核失败)"
                                      ),
                                    ])
                                  : _c("span", { staticClass: "remark" }, [
                                      _vm._v(_vm._s(item.nickname)),
                                    ]),
                              ]
                            ),
                          ]),
                          item.update_time
                            ? _c("span", { staticClass: "remark" }, [
                                _vm._v(_vm._s(item.update_time)),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "df fdr alc" }, [
                          indexALl + 1 === _vm.detail.process.length
                            ? _c("div")
                            : item.status === 2
                            ? _c("div", { staticClass: "line" })
                            : item.status === 3
                            ? _c("div", {
                                staticClass: "dash-line",
                                staticStyle: { height: "0.94rem" },
                              })
                            : _c("div", { staticClass: "dash-line" }),
                          item.status === 3 ||
                          (item.status === 4 && item.content)
                            ? _c("div", {
                                staticClass: "refuse-reason df alc",
                                domProps: { innerHTML: _vm._s(item.content) },
                              })
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e()
                }),
                itemAll.length > 1
                  ? _c("div", [
                      _c("div", { staticClass: "df fdr jc-sb" }, [
                        _c("div", { staticClass: "df fdr" }, [
                          _c("div", { staticStyle: { position: "relative" } }, [
                            _c(
                              "div",
                              { staticClass: "item-list flex-center" },
                              [
                                _c("icon-svg", {
                                  staticClass: "bell",
                                  attrs: {
                                    "icon-class": "yi_icon_xiaoxi_whitex",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "df fdc jc-sb",
                              staticStyle: { position: "relative" },
                            },
                            [
                              _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(itemAll.length) + "个审批人"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "location df fdr" },
                                _vm._l(itemAll, function (i) {
                                  return _c(
                                    "div",
                                    { staticClass: "img-list df fdc" },
                                    [
                                      i.heagurl
                                        ? _c("img", {
                                            attrs: {
                                              src: i.heagurl,
                                              alt: "avatar",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("img", {
                                        attrs: {
                                          src: require("../../../assets/ic_head_default@3x.png"),
                                          alt: "avatar",
                                        },
                                      }),
                                      _c("span", { staticClass: "remark" }, [
                                        _vm._v(_vm._s(i.nickname)),
                                      ]),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "df fdr alc" }, [
                        indexALl + 1 === _vm.detail.process.length
                          ? _c("div", { staticStyle: { height: "1.22rem" } })
                          : itemAll.length > 1
                          ? _c("div", {
                              staticClass: "dash-line",
                              staticStyle: { height: "1.22rem" },
                            })
                          : _c("div", { staticClass: "dash-line" }),
                      ]),
                    ])
                  : _vm._e(),
              ],
              2
            )
          }),
          0
        )
      : _vm._e(),
    _vm.status === 1
      ? _c("div", { staticStyle: { height: "1.2rem" } })
      : _vm._e(),
    _vm.status === 1
      ? _c("footer", { staticClass: "df fdr alc jc-sb" }, [
          _c(
            "button",
            {
              staticClass: "reject-btn",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toRefuse.apply(null, arguments)
                },
              },
            },
            [_vm._v("拒绝")]
          ),
          _c(
            "button",
            {
              staticClass: "allow-btn",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.passExamine.apply(null, arguments)
                },
              },
            },
            [_vm._v("批准")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "df fdr alc jc-sb" }, [
      _c("div", { staticClass: "name" }, [_vm._v("采购商品")]),
      _c("div", { staticClass: "nav-detail" }, [_vm._v("查看详情")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }